import React from 'react';
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import UndoIcon from '@material-ui/icons/Undo';
import DoneIcon from '@material-ui/icons/Done';
import BackspaceIcon from '@material-ui/icons/Backspace';

function ControlButtonSection({setCurrentScore, currentScore, players, setPlayers, history, setHistory}) {
    const style = {display: "flex", justifyContent: "center"}
    const handleDelete = () => {
        setCurrentScore(s => {
            return s.substring(0, s.length - 1)
        })
    }
    const handleAddTwelve = () => {
        setCurrentScore(s => {
            let parsed = parseInt(s)
            if (isNaN(parsed)) parsed = 0;
            return (parsed + 12) + ""
        })
    }
    const handleDone = () => {
        const activePlayerId = Object.keys(players).find(p => players[p].active)
        const activePlayer = players[activePlayerId]
        let parsed = parseInt(currentScore)
        if (isNaN(parsed)) parsed = 0;
        const updatedActivePlayer = {name: activePlayer.name, score: activePlayer.score + parsed}
        let found = false
        let nextPlayerId
        Object.keys(players).forEach(k => {
            if (found) {
                nextPlayerId = k
                found = false
            }
            found = players[k].active
        })
        if (found) nextPlayerId = Object.keys(players)[0]
        setHistory(s => {
            return s.concat({slot: activePlayerId, score: parsed})
        })
        const nextPlayer = players[nextPlayerId]
        setPlayers(s => {
            return {...s, [activePlayerId]: updatedActivePlayer, [nextPlayerId]: {...nextPlayer, active: true}}
        })
        setCurrentScore("")
    }

    const handleRevert = () => {
        const lastStep = history[history.length - 1]
        if (lastStep) {
            const activePlayerId = Object.keys(players).find(p => players[p].active)
            const activePlayer = players[activePlayerId]
            const {slot, score} = lastStep;
            setHistory(s => {
                s.pop();
                return [...s];
            })
            setPlayers(s => {
                return {
                    ...s,
                    [slot]: {...players[slot], score: players[slot].score - score, active: true},
                    [activePlayerId]: {...activePlayer, active: false}
                }
            })
        }
    }

    return (
        <Grid style={style} direction="column" container spacing={4}>
            <IconButton onClick={handleDelete}>
                <BackspaceIcon/>
            </IconButton>
            <IconButton onClick={handleRevert}>
                <UndoIcon/>
            </IconButton>
            <Button onClick={handleAddTwelve}>
                +12
            </Button>
            <IconButton onClick={handleDone}>
                <DoneIcon/>
            </IconButton>
        </Grid>
    );
}

export default ControlButtonSection;