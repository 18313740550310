import React from 'react'
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import MaterialToolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import PersonIcon from '@material-ui/icons/Person'
import ReplayIcon from '@material-ui/icons/Replay'
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import PlayerCountModal from './PlayerCountModal'
import PlayerNameModal from './PlayerNameModal'
import DialogContentText from "@material-ui/core/DialogContentText";

function Toolbar({setHistory, setPlayers, playerCount, setPlayerCount, playerNames, setPlayerNames, setCurrentScore}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [playerCountModalOpen, setPlayerCountModalOpen] = React.useState(false);
    const [playerNameModalOpen, setPlayerNameModalOpen] = React.useState(false);
    const [confirmRestartModalOpen, setConfirmRestartModalOpen] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenPlayerCountModal = () => {
        setPlayerCountModalOpen(true);
        handleClose();
    };

    const handleClosePlayerCountModal = () => {
        setPlayerCountModalOpen(false);
    };

    const handleOpenPlayerNameModal = () => {
        setPlayerNameModalOpen(true);
        handleClose();
    };

    const handleClosePlayerNameModal = () => {
        setPlayerNameModalOpen(false);
    };

    const handleOpenConfirmRestartModal = () => {
        setConfirmRestartModalOpen(true);
    };

    const handleCloseConfirmRestartModal = () => {
        setConfirmRestartModalOpen(false);
    };

    const handleRestart = () => {
        setCurrentScore("")
        setHistory([])
        setPlayers(s => {
                return Object.keys(s).reduce((acc, v) => {
                    return {...acc, [v]: {...s[v], score: 0, active: v === "1"}};
                }, {})
            }
        )
    }

    return (
        <AppBar position="static">
            <MaterialToolbar>
                <Typography variant="h6" style={{flexGrow: 1}}>
                    Quickle
                </Typography>
                <Button onClick={handleOpenConfirmRestartModal}>
                    <ReplayIcon style={{color: "white"}}/>
                </Button>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <PersonIcon style={{color: "white"}}/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleOpenPlayerCountModal}>Anzahl Spieler</MenuItem>
                    <MenuItem onClick={handleOpenPlayerNameModal}>Spieler verwalten</MenuItem>
                </Menu>
                <PlayerCountModal open={playerCountModalOpen} handleClose={handleClosePlayerCountModal}
                                  setPlayerCount={setPlayerCount} setPlayers={setPlayers} playerCount={playerCount}/>
                <PlayerNameModal open={playerNameModalOpen} handleClose={handleClosePlayerNameModal}
                                 playerNames={playerNames} setPlayerNames={setPlayerNames}/>
                <Dialog open={confirmRestartModalOpen} handleClose={handleCloseConfirmRestartModal}>
                    <DialogTitle id="simple-dialog-title">Spiel neustarten</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Alle Spielstände werden zurückgesetzt!</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirmRestartModal} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={() => {
                            handleRestart()
                            handleCloseConfirmRestartModal()
                        }} color="primary">
                            Neustarten
                        </Button>
                    </DialogActions>
                </Dialog>
            </MaterialToolbar>
        </AppBar>);
}

export default Toolbar