import React from 'react';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

function PlayerCountModal({open, handleClose, playerCount, setPlayerCount, setPlayers}) {
    const handleListItemClick = (newPlayerCount) => {
        if (newPlayerCount !== playerCount) {
            setPlayers(s => {
                let filteredKeys = Object.keys(s).filter(p => parseInt(p) <= newPlayerCount)
                const difference = newPlayerCount - filteredKeys.length;
                if (difference > 0) {
                    for (let i = playerCount + 1; i <= newPlayerCount; i++) {
                        filteredKeys.push(i + "")
                    }
                }
                return filteredKeys.reduce((acc, v) => {
                    let player = s[v]
                    console.log("Player", player, v)
                    if (!player) player = {name: "Spieler", score: 0}
                    acc[v] = player
                    return acc;
                }, {})
            })

            setPlayerCount(newPlayerCount)
        }
        handleClose()
    };

    return (
        <Modal style={{display: "flex", justifyContent: "center", alignItems: "center"}}
               open={open}
               onClose={handleClose}
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
        >
            <div style={{backgroundColor: "white"}}>
                <List>
                    <ListItem
                        button
                        selected={playerCount === 2}
                        onClick={() => handleListItemClick(2)}
                    >
                        2 Spieler
                    </ListItem>
                    <Divider/>
                    <ListItem
                        button
                        selected={playerCount === 3}
                        onClick={() => handleListItemClick(3)}
                    >
                        3 Spieler
                    </ListItem>
                    <Divider/>
                    <ListItem
                        button
                        selected={playerCount === 4}
                        onClick={() => handleListItemClick(4)}
                    >
                        4 Spieler
                    </ListItem>
                    <Divider/>
                    <ListItem
                        button
                        selected={playerCount === 5}
                        onClick={() => handleListItemClick(5)}
                    >
                        5 Spieler
                    </ListItem>
                </List>
            </div>
        </Modal>
    );
}

export default PlayerCountModal;