import React from 'react';
import Button from "@material-ui/core/Button"
import useLongPress from "./useLongPress"

function PlayerButton({ children, onLongPress, slot, onClick }) {
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 600,
        slot
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    return (<Button {...longPressEvent} size="medium" color="primary" >
        {children}
    </Button>);
}

export default PlayerButton;