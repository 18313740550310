import React from 'react';
import Grid from "@material-ui/core/Grid"
import PlayerSectionItem from "./PlayerSectionItem"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

function PlayerSection({ playerCount, playerNames, setPlayers, players}) {
    const [state, setState] = React.useState({anchorEl: null, slot: null});

    const handleClick = (slot) => {
        setPlayers(s => {
            const activePlayerId = Object.keys(players).filter(p => players[p].active)
            const activePlayer = players[activePlayerId]
            return {
                ...s,
                [activePlayerId]: {name: activePlayer.name, score: activePlayer.score},
                [slot]: {...s[slot], active: true}
            }
        })
    }

    const handleLongPress = (anchorEl, slot) => {
        setState({anchorEl, slot});
    };

    const handleClose = () => {
        setState({anchorEl: null, slot: null});
    };
    const handleMenuItemClick = (playerId) => {
        setPlayers(s => {
            return {...s, [state.slot]: {...s[state.slot], name: playerNames[playerId]}}
        })
        handleClose()
    }
    const renderItems = (playerCount) => {
        return Object.keys(players).map((slot, i) =>
            <PlayerSectionItem key={slot} name={players[slot].name} score={players[slot].score} handleClick={handleClick}
                               handleLongPress={handleLongPress} slot={slot} active={players[slot].active}/>
        )
    };

    const renderMenuItems = (playerNames) => {
        return Object.keys(playerNames).map((playerId, i) =>
            <MenuItem key={playerId} onClick={() => handleMenuItemClick(playerId)}>
                {playerNames[playerId]}
            </MenuItem>
        )
    };
    return (
        <div style={{display: "flex", justifyContent: 'center'}}>
            <Grid style={{marginTop: "15px", display: "flex", justifyContent: "center"}} container item xs={12}
                  spacing={4}>
                {renderItems(playerCount)}
                <Menu
                    id="simple-menu"
                    anchorEl={state.anchorEl}
                    keepMounted
                    open={Boolean(state.anchorEl)}
                    onClose={handleClose}
                >{renderMenuItems(playerNames)}
                </Menu>
            </Grid>
        </div>
    );
}

export default PlayerSection;