import React from 'react';
import './App.css';
import NumberInputSection from "./NumberInputSection"
import PlayerSection from "./PlayerSection"
import ControlButtonSection from './ControlButtonSection';
import NumberInputDisplay from './NumberInputDisplay';
import Toolbar from "./Toolbar"

const defaultPlayers = {
    1: {name: "Malte", score: 0, active: true},
    2: {name: "Merle", score: 0},
    3: {name: "Lara", score: 0}
}
const defaultPlayerNames = {1: "Flo", 2: "Malte", 3: "Lara", 4: "Merle"}
const defaultPlayerCount = 3;

function App() {
    const [playerCount, setPlayerCount] = React.useState(defaultPlayerCount);
    const [currentScore, setCurrentScore] = React.useState("");
    const [players, setPlayers] = React.useState(defaultPlayers);
    const [playerNames, setPlayerNames] = React.useState(defaultPlayerNames);
    const [history, setHistory] = React.useState([]);
    return (
        <div className="App">
            <Toolbar setHistory={setHistory} setCurrentScore={setCurrentScore} playerCount={playerCount}
                     setPlayerCount={setPlayerCount}
                     playerNames={playerNames} setPlayerNames={setPlayerNames} setPlayers={setPlayers}/>
            <div>
                <PlayerSection setPlayers={setPlayers} playerCount={playerCount} players={players}
                               playerNames={playerNames}/>
                <NumberInputDisplay currentScore={currentScore} style={{marginTop: "50px", fontSize: "40px"}}/>
                <div style={{position: "relative", flexDirection: "row", marginTop: "30px", justifyContent: "center"}}>
                    <NumberInputSection setCurrentScore={setCurrentScore}/>
                    <div style={{position: "absolute", top: 20, left: "75%"}}>
                        <ControlButtonSection history={history} setHistory={setHistory}
                                              setCurrentScore={setCurrentScore} currentScore={currentScore}
                                              players={players} setPlayers={setPlayers}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
