import React from 'react';
import PlayerButton from "./PlayerButton"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

function PlayerSectionItem({children, name, score, active, handleLongPress, slot, handleClick}) {
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
            <PlayerButton onClick={handleClick} onLongPress={handleLongPress} slot={slot}>{name}</PlayerButton>
            <div style={{fontSize: "20px"}}>{score}</div>
            {active && <ArrowDropUpIcon/>}
        </div>
    );
}

export default PlayerSectionItem;