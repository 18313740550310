import React from 'react';
import Button from "@material-ui/core/Button"

function NumberButton({children, onClick}) {
return (<Button onClick={onClick} style={{fontSize:"40px"}} >
{children}
    </Button>);
}

export default NumberButton;

