import React from 'react';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton"
import {v4 as uuidv4} from 'uuid';
import DeleteIcon from "@material-ui/icons/Delete"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

function PlayerNameModal({open, handleClose, playerNames, setPlayerNames}) {
    const handleDeletePlayer = (playerId) => {
        setPlayerNames(s => {
            delete s[playerId]
            console.log(s)
            return {...s}
        })
    };
    const handleAddPlayer = () => {
        setPlayerNames(s => {
            return {...s, [uuidv4()]: ""}
        })
    };

    const handleEditPlayer = (text, playerId) => {
        setPlayerNames(s => {
            return {...s, [playerId]: text}
        })

    };

    const renderItems = (playerNames) => {
        return Object.keys(playerNames).map((playerId, i) =>
            <ListItem key={playerId}>
                <TextField label="Name" value={playerNames[playerId]}
                           onChange={(e) => handleEditPlayer(e.currentTarget.value, playerId)}/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePlayer(playerId)}>
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        )
    };

    return (
        <Modal style={{display: "flex", justifyContent: "center", alignItems: "center"}}
               open={open}
               onClose={handleClose}
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
        >
            <div style={{backgroundColor: "white"}}>
                <List>
                    {renderItems(playerNames)}
                    <IconButton onClick={handleAddPlayer}>
                        <AddIcon/>
                    </IconButton>
                </List>
            </div>
        </Modal>
    );
}

export default PlayerNameModal;