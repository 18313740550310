import React from 'react';
import NumberButton from "./NumberButton"
import Grid from "@material-ui/core/Grid"

function NumberInputSection({ children, setCurrentScore }) {
  const handleClick = (number) => {
    setCurrentScore(s => { return s.concat(number) })
  }
  return (<Grid direction="column" container spacing={1}>
    <Grid style={{ alignItems: "center", justifyContent: "center" }} container spacing={3}>
      <NumberButton onClick={() => handleClick(1)}>1</NumberButton>
      <NumberButton onClick={() => handleClick(2)}>2</NumberButton>
      <NumberButton onClick={() => handleClick(3)}>3</NumberButton>

    </Grid>
    <Grid style={{ alignItems: "center", justifyContent: "center" }} container spacing={3}>
      <NumberButton onClick={() => handleClick(4)}>4</NumberButton>
      <NumberButton onClick={() => handleClick(5)}>5</NumberButton>
      <NumberButton onClick={() => handleClick(6)}>6</NumberButton>

    </Grid>
    <Grid style={{ alignItems: "center", justifyContent: "center" }} container spacing={3}>
      <NumberButton onClick={() => handleClick(7)}>7</NumberButton>
      <NumberButton onClick={() => handleClick(8)}>8</NumberButton>
      <NumberButton onClick={() => handleClick(9)}>9</NumberButton>
    </Grid>
    <Grid style={{ alignItems: "center", justifyContent: "center" }} container spacing={3}>
      <NumberButton onClick={() => handleClick(0)}>0</NumberButton>
    </Grid>
  </Grid>);
}

export default NumberInputSection;